$(function() {

    $("#arr").datepicker({
        numberOfMonths: 2,
        dateFormat: "dd/mm/yy",
		setDate: new Date()
    }).datepicker('setDate', new Date());

    $("#dep").datepicker({
        numberOfMonths: 2,
        dateFormat: "dd/mm/yy",
		setDate: '+1d'
    }).datepicker('setDate', '+1d');

    $('.check select').selectBoxIt({
        showEffect: 'fadeIn',
        showEffectSpeed: 200,
        hideEffect: 'fadeOut',
        hideEffectSpeed: 200
    });

});

/**
 * Translate datepicker to  portuguese
 */
$.datepicker.regional["br"] = {
    closeText: "Fechar",
    prevText: "&#x3C;Anterior",
    nextText: "Próximo&#x3E;",
    currentText: "Hoje",
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
        "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ],
    dayNames: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    weekHeader: "Sm",
    dateFormat: "dd/mm/yy",
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ""
};

/**
 * Tłumaczenie datepickera na język polski
 */
$.datepicker.regional["pl"] = {
    closeText: "Zamknij",
    prevText: "&#x3C;Poprzedni",
    nextText: "Następny&#x3E;",
    currentText: "Dziś",
    monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec",
        "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"
    ],
    monthNamesShort: ["Sty", "Lu", "Mar", "Kw", "Maj", "Cze",
        "Lip", "Sie", "Wrz", "Pa", "Lis", "Gru"
    ],
    dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    dayNamesShort: ["Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So"],
    dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
    weekHeader: "Tydz",
    dateFormat: "dd/mm/yy",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ""
};
