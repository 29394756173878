(function() {
    app.filter('slice', function() {
        return function(arr, start, end) {
            return (arr || []).slice(start, end);
        };
    });
    app.filter('switchmonth', function() {
        return function(arr, start, end) {
            var m = (arr || []).slice(start, end);

            if ($("[lang=pl]")) {
                var trans = [
                    "0",
					"Sty","Lu","Mar","Kw","Maj","Cze",
					"Lip","Sie","Wrz","Pa","Lis","Gru"
                ];
            }
            if ($("[lang=br]")) {
                var trans = [
                    "0",
                    "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
                    "Jul", "Ago", "Set", "Out", "Nov", "Dez"
                ];
            }
            if ($("[lang=en]")) {
                var trans = [
                    "0",
                    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
            }
            return trans[parseInt(m)];
        };
    });

    app.directive('gbook', function($http) {
        return {
            restrict: 'E',
            template: '<div class="questbook bg gb-content col-xs-12">\
				<div class="review col-xs-12 col-sm-6 col-md-8"  ng-repeat="item in items | limitTo:1">\
					<div class="review-title">{{ item.title }}</div>\
					<div class="review-content">{{ item.content | limitTo: 300}}...</div>\
					<div class="review-author col-md-4">– <strong>{{ item.name }}</strong> <span>({{ item.location }})</span></div>\
					<a href="{{gblink}}" class="col-xs-12 col-sm-6 col-md-5 button-vb btn-vb btn-vb-grey btn-vba review-write"><span>{{btninfo}}</span></a>\
				</div>\
				<div class="review col-xs-12 col-sm-6 col-md-8"  ng-show="items==0">\
					<a href="{{gblink}}" class="col-xs-12 col-sm-6 col-md-5 button-vb btn-vb btn-vb-grey btn-vba review-write"><span>{{btninfo}}</span></a>\
				</div>\
				<div class="reviews-list col-xs-12 col-sm-6 col-md-4"  ng-repeat="item in items | limitTo:3" ng-if="$index != 0">\
					<a href="{{gblink}}">\
						<div class="recent-review">\
							<div class="recent-review-date">\
								<span class="recent-review-date-month">{{  item.created_at | switchmonth: 5:7  }}</span>\
								<span class="recent-review-date-day">{{ item.created_at | slice: 8:10 }}</span>\
							</div>\
							<div class="recent-review-title">{{ item.title | limitTo: 30 }}</div>\
							<div class="recent-review-content">{{ item.content | limitTo: 115}}...</div>\
						</div>\
					</a>\
				</div>\
			</div>',
            scope: {
                btninfo: '@',
                gblink: '@'
            },
            link: function(scope, element, attr) {
                scope.items = {};

                $http({
                    method: 'GET',
                    url: '/guestbook/get',

                }).then(function successCallback(response) {
                    scope.items = response.data;

                }, function errorCallback(response) {
                    console.log('Błąd');
                });
            }
        };
    });
})();
