$(function() {
    $(".portfolio").fadeTo(400, 1);
    var selectedClass = "";

    $(".btn-tooltip").click(function() {
        selectedClass = $(this).attr("data-rel");
        $(".btn-tooltip").removeClass('active')
        $(this).addClass('active');
        $(".portfolio").fadeTo(100, 0.1);
        $(".portfolio div").not("." + selectedClass).fadeOut().removeClass('scale-anm');
        setTimeout(function() {
            $("." + selectedClass).fadeIn().addClass('scale-anm');
            $(".portfolio").fadeTo(300, 1);
        }, 300);
    });

    if (window.matchMedia("(min-width: 700px)").matches) {

        $(".fancybox")
            .attr('rel', 'gallery')
            .fancybox({
                padding: 0,

                beforeShow: function() {
                    $href = (location.host + this.href);
                    this.title += ' <a href="https://twitter.com/share" class="twitter-share-button" data-count="none" data-url="http://' + $href + '">Tweet</a> ';
                    this.title += ' <iframe src="https://www.facebook.com/plugins/share_button.php?href=http%3A%2F%2F' + encodeURIComponent($href) + '&layout=button_count&mobile_iframe=true&width=114&height=20&appId" width="114" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>';
                    if (this.title) {}
                },
                afterShow: function() {
                    // Render tweet button
                    twttr.widgets.load();
                },
                helpers: {
                    title: {
                        type: 'inside'
                    }, //<-- add a comma to separate the following option
                    buttons: {} //<-- add this for buttons
                },
                closeBtn: true, // you will use the buttons now
                arrows: true
            });
    } else {
        $('.isotope-item').click(function() {
            return false;
        });
    };
	$(".fancybox").click( function( e ) {
if ( window.innerWidth < 500 ) {
	e.stopPropagation();
	e.preventDefault();
}
});
    $('.testimonial-add').click(function() {
        $('.wrapper-add-comment, .add-comment ').css('display', 'block');
    })
    $('.wrapper-add-comment').click(function() {
        $('.wrapper-add-comment, .add-comment ').css('display', 'none');
    })

});
var slanguage= $('.language');
var slanguagefirst= $('.language .lang-select');
slanguage.click(function(e) {
	if(!(slanguage.hasClass('lvisible'))){
		e.preventDefault();
		slanguage.addClass('lvisible');
		slanguagefirst.addClass('brbnone');
	}else{
		slanguage.removeClass('lvisible');
		slanguagefirst.removeClass('brbnone');
	}

});

var app = angular.module('frontend', []);
app.filter('datefilter', function() {
    return function(item) {
        if (item) {
            return item.replace(/\//g, '')
        }
    }
});
