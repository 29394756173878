(function() {
    app.directive('guestBook', function($http) {
        return {
            restrict: 'E',
            template: '<div class="testimonial"  ng-repeat="item in items">\
							<div class="testimonial-title">\
								<i class="fa fa-quote-left"></i>\
								<h2>{{item.title}}</h2>\
							</div>\
							<div class="testimonial-desc">\
								{{item.content}}\
							</div>\
							<div class="testimonial-autor text-uppercase">\
								– <strong>{{item.name}}</strong>\
	                                <span>( {{item.location}})</span>\
							</div>\
						</div>',
            link: function(scope, element, attr) {
                scope.items = {};
                $http({
                    method: 'GET',
                    url: '/guestbook/get',

                }).then(function successCallback(response) {
                    scope.items = response.data;
                }, function errorCallback(response) {
                    console.log('Błąd');
                });
            }
        };
    });
})();
