/**
 * SiteMap directive
 * generate sitemap list from /sitemap.xml
 */
(function() {

    app.directive('siteMap', function($http) {
        return {
            restrict: 'E',
            template: '<ul class="site-map-list"> \
				<li ng-repeat="link in sitemapLinks"><a class="side-bar__link"  href="{{ link.loc }}">{{ link.loc }}</a></li> \
			   </ul>',
            link: function(scope, element, attr) {
                scope.sitemapLinks = {};
                $http({
                    method: 'GET',
                    url: '/sitemap.xml',
                    transformResponse: function(data) {
                        var x2js = new X2JS();
                        return x2js.xml2js(data);
                    }
                }).then(function successCallback(response) {
                    scope.sitemapLinks = response.data.urlset.url;
                }, function errorCallback(response) {
                    console.log('Błąd odczytu pliku SiteMap.');
                });
            }
        };


    });
})();
